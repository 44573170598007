import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { CSSProperties } from 'react'
import Logo from '../../img/pux-biq-logo.svg'
import LogoInverted from '../../img/pux-biq-logo-bile.svg'
import { Sticky } from 'react-sticky'
import { getFromConfig, getLocalizedUrl } from '../../utils/localeURL'
import { useHeaderData } from './_use-header-data.js'
import { Icon } from '../builders/icon-builder'
import { useLocation } from '@reach/router'
import { FooterData, FooterItem, renderFooterItem } from './footer'
import { useFooterData } from './_use-footer-data.js'
import PuxLanguageSelector, {
  localizedPathType,
} from '../PuxLanguageSelector/PuxLanguageSelector'

let { LOCALE } = process.env
LOCALE = LOCALE ? LOCALE : `cs`
const localizedHomepageUrl = `/${getFromConfig(LOCALE, `urlPrefix`, `PuxDesignGatsby`)}`
const activeHrefLang = `${getFromConfig(LOCALE, `isoCode`, `PuxDesignGatsby`)}`

interface TopMenuItem {
  render: string
  contentMenuItemTheme: string
  contentMenuItemIcon: string
  menuItemsList: {
    menuItems: [MenuItem]
  }
}

interface MenuItem {
  render: string
  contentMenuItemDescription: string
  contentMenuItemIcon: string
}

interface HeaderData {
  menuItems: [TopMenuItem]
}

interface RenderData {
  url: string
  name: string
}

const renderMenuItem = (item: MenuItem) => {
  const renderData: RenderData = JSON.parse(item.render)

  return (
    <li>
      <Link to={getLocalizedUrl(renderData.url)}>
        <div className="content">
          {item.contentMenuItemIcon ? <Icon id={item.contentMenuItemIcon} color="#FFF" /> : null}
          <big>{renderData.name}</big>
          <span>{item.contentMenuItemDescription}</span>
        </div>
      </Link>
    </li>
  )
}

// const renderTopMenuItem:React.FC = (item: TopMenuItem) => {

// }

interface TopMenuItemProps {
  item: TopMenuItem,
  delay: number
}

const TopMenuItemComponent = (props: TopMenuItemProps) => {
  const { item } = props
  let renderData: RenderData;

  if (item.contentType === 'ContentMenuItem') {
    console.log("🚀 ~ TopMenuItemComponent ~ item:", item)
    renderData = JSON.parse(item.render)
  } else {
    renderData = item
  }
  const hasDropdown = item.menuItemsList != null
  const [expanded, setExpanded] = React.useState(false)
  const classNameLi = `${hasDropdown ? `dropdown` : ``}${item.contentMenuItemTheme ? ` ${item.contentMenuItemTheme}` : ``
    }${expanded ? ` expanded` : ``}`
  const classNameA = `${hasDropdown ? `triangle` : ``}`

  return (
    <li className={classNameLi} style={{ transitionDelay: `${props.delay}s` }}>
      {item.contentType === 'ContentMenuItem' ? (
        <Link to={getLocalizedUrl(renderData.url)}>
          {renderData.name}
          <div className={classNameA}></div>
        </Link>
      ) : null}
      {item.contentType === 'LinkMenuItem' ? (
        <a target="_blank" href={renderData.linkMenuItem.url}>
          {renderData.linkMenuItem.name}
          <div className={classNameA}></div>
        </a>
      ) : null}
      {hasDropdown && (
        <>
          <div className={`submenu`}>
            <ul>{item.menuItemsList.menuItems.map((item) => renderMenuItem(item))}</ul>
          </div>
          <div className="expander" onClick={() => setExpanded(!expanded)}></div>
        </>
      )}
    </li>
  )
}

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const headerData: HeaderData = useHeaderData()
  const footerData: FooterData = useFooterData()
  const localizedPaths = props.localizedPaths

  console.log("🚀 ~ Header ~ headerData:", headerData)

  return (
    <Sticky>
      {({ style, distanceFromTop }: { style: CSSProperties; distanceFromTop: number }) => (
        <header style={style} className={`${isOpen ? `open` : ``} ${distanceFromTop < 0 ? `scrolled` : ``}`}>
          <div className="pux-container">
            <div className="header">
              <Link hrefLang={activeHrefLang} to={localizedHomepageUrl} className="logo">
                <img src={Logo} alt="PUXDesign"></img>
              </Link>
              <Link hrefLang={activeHrefLang} to={localizedHomepageUrl} className="logo-inverted">
                <img src={LogoInverted} alt="PUXDesign"></img>
              </Link>
              <nav>
                <ul>
                  {headerData.menuItems.map((item, idx) => (
                    <TopMenuItemComponent item={item} key={idx} delay={0.31 + (idx * 0.06)} />
                  ))}
                </ul>
                <ul className="respo-footer">
                  {footerData.menuItems.map((item, idx: number) => renderFooterItem(item, ((headerData.menuItems.length * 0.06) + 0.31) + (idx * 0.06)))}
                </ul>
              </nav>
              <PuxLanguageSelector localizedPaths={localizedPaths} />
              <div id="burger" onClick={() => setIsOpen(!isOpen)}></div>
            </div>
          </div>
        </header>
      )}
    </Sticky>
  )
}

export default Header
